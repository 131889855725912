// Erstellen Sie ein Text-Layer-Objekt
let myTextLayer = new TextLayer({
    text: "Die Sonne scheint auf die blau&shy;en Berge.",
    x: 50,
    y: 50,
    fontSize: 24,
})

// Fügen Sie den Text-Layer-Objekt zur Szene hinzu
myTextLayer.addToPage()
